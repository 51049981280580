import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { GlobalStyle, Link, Stack, Text } from '@procsea/design-system'

import useDocumentTitle from 'src/hooks/useDocumentTitle'
import klarysLogo from 'src/images/klarys-logo.svg'
import signupDoneFirstStep from 'src/images/signup-done-first-step.svg'

import { LocaleParams } from '../../../types'
import { AuthContainer, AuthMainCard, HeaderLogo } from './common'
import { StyledRouterLink } from './styles'

const StyledItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 4px;
  margin: ${props => props.theme.spacings.medium} 0;
  padding: ${props => props.theme.spacings.medium} ${props => props.theme.spacings.xlarge};
  text-align: left;
  background-color: ${props => props.theme.styles.light.background};
`

const StyledItemImage = styled.img`
  margin-right: ${props => props.theme.spacings.xlarge};
  width: auto;
  height: 40px;
`

const SignupDone = () => {
  useDocumentTitle(gettext('Signup succeeds'))
  const { locale } = useParams<LocaleParams>()

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />
      <a data-e2e="login-home-link" href={window.WEBSITE_URL || '/'}>
        <HeaderLogo alt="Klarys logo" src={klarysLogo} />
      </a>
      <AuthMainCard shadow="middle" spacing="xlarge">
        <Stack spacing="medium">
          <Text size="xxxlarge" variant="secondary">
            {gettext('Congratulations!')}
          </Text>
          <Text variant="black">
            {gettext('What happens now? Klarys takes care of everything!')}
          </Text>
          <Text variant="black">{gettext('Within 24 hours maximum (if working day):')}</Text>
        </Stack>
        <StyledItem>
          <StyledItemImage src={signupDoneFirstStep} />
          <Text>
            {gettext(
              'You will receive a confirmation e-mail as soon as your account is validated.'
            )}
          </Text>
        </StyledItem>
      </AuthMainCard>
      <Link data-e2e="signup-done-learn-more-link" href={window.WEBSITE_URL || '/'}>
        {gettext('Learn more about Klarys')}
      </Link>
      <Text variant="neutral">
        {gettext('Already using Klarys?')}&nbsp;
        <StyledRouterLink data-e2e="signup-done-sign-in-link" to={`/${locale}/accounts/login`}>
          {gettext('Sign in here')}
        </StyledRouterLink>
      </Text>
    </AuthContainer>
  )
}

export default SignupDone
